import React, { useEffect, useState, useCallback } from "react"
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import { scrollSpy } from "react-scroll"
import cx from "classnames"
import Img from "gatsby-image"

import SmartLink from "../SmartLink"
import Toggler from "../../Toggler"
import styles from "./Header.module.scss"

const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      headerLogo: file(relativePath: { eq: "headerLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => scrollSpy.update(), [])

  const handleClick = useCallback(isActive => {
    setIsMenuVisible(isActive)
  }, [])

  return (
    <header className={styles.header}>
      <nav>
        <div className={styles.container}>
          <div className={styles.logo}>
            <GatsbyLink className={styles.logo} to="/">
              {/* <img src={Logo} alt="Aramaze" /> */}
              <Img fluid={data.headerLogo.childImageSharp.fluid} />
            </GatsbyLink>
          </div>
          <Toggler onChange={handleClick} className={styles.toggler} />
          <div className={cx(styles.navbar, { [styles.show]: isMenuVisible })}>
            <ul>
              <li>
                <SmartLink
                  pathForScroll="/"
                  className={styles.navItem}
                  to="home"
                  activeClass={styles.active}
                  spy
                  hashSpy
                  smooth
                  duration={500}
                >
                  Home
                </SmartLink>
              </li>
              <li>
                <SmartLink
                  pathForScroll="/"
                  className={styles.navItem}
                  to="services"
                  activeClass={styles.active}
                  spy
                  hashSpy
                  smooth
                  duration={500}
                >
                  Services
                </SmartLink>
              </li>
              <li>
                <SmartLink
                  pathForScroll={"/"}
                  className={styles.navItem}
                  to="technologies"
                  activeClass={styles.active}
                  spy
                  hashSpy
                  smooth
                  duration={500}
                >
                  Technologies
                </SmartLink>
              </li>
              <li>
                <SmartLink
                  pathForScroll="/"
                  className={styles.navItem}
                  to="projects"
                  activeClass={styles.active}
                  spy
                  hashSpy
                  smooth
                  duration={500}
                >
                  Portfolio
                </SmartLink>
              </li>
              <li>
                <GatsbyLink
                  to="/about-us/"
                  activeClassName={styles.active}
                  className={styles.navItem}
                >
                  About Us
                </GatsbyLink>
              </li>
              <li>
                <GatsbyLink className={styles.highlightedBtn} to="/contact-us/">
                  Contact us
                </GatsbyLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
