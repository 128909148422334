import { useEffect, useState } from "react"
import {gtagWrapper} from "../utils/gtagHelper";

function initCrispChat({ id } = {}) {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = id || "7a5840b1-2533-45c1-9606-873dc9312905"

  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.src = "https://client.crisp.chat/l.js"
    script.async = 1
    script.addEventListener("load", () => {
      resolve()
    })
    script.addEventListener("error", e => {
      reject(e)
    })
    document.getElementsByTagName("head")[0].appendChild(script)
  })
}

const useChat = ({ user, id } = {}) => {
  const [isReady, setIsReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isReady && !isLoading) {
      setIsLoading(true)
      initCrispChat({ id })
        .then(() => {
          setIsReady(true)
          setIsLoading(false)
          if(window.$crisp){
            window.$crisp.push(["on", "chat:opened", ()=>{
              gtagWrapper('event', 'click-chat-widget', { 'event_category': 'click', })
            }])
          } 
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error("Client chat was not loaded.", e)
          setIsReady(false)
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isReady && user) {
      window.$crisp.push(["set", "user:email", [user.email]])
      window.$crisp.push([
        "set",
        "user:nickname",
        [`${user.firstName} ${user.lastName}`],
      ])
    }
  }, [isReady, user])

  return { isReady }
}

export default useChat
