import React from "react"
import IcoMoon from "react-icomoon"
import propTypes from "prop-types"
import iconSet from "./iconSet"

const Icon = ({ icon, size, style, ...rest }) => {
  return (
    <IcoMoon
      iconSet={iconSet}
      icon={icon}
      size={size}
      style={style}
      {...rest}
    />
  )
}

/**  Add icon to iconSet.
 *
 example for icons:
  [{
    icon: {
         paths: [
           "M512 96c-111.118 0-215.584 43.272-294.156 121.844s-121.844"
         ],
         attrs: []
       },
     properties: {
       name: "example"
     }
  }]
 */
Icon.add = icons => {
  icons.forEach(icon => iconSet.icons.push(icon))
}

Icon.defaultProps = {
  size: 18,
  style: {},
}

Icon.propTypes = {
  /** Name of the Icon */
  icon: propTypes.string.isRequired,
  /** Set size fo the icon */
  size: propTypes.number,
  /** Set svg icon styles , the other props is the same as default svg's */
  style: propTypes.object,
}

export default Icon
