import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Icon from "../../Icon"
import FooterLink from "./FooterLink"
import {gtagWrapper} from "../../../../utils/gtagHelper";
import styles from "./Footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allStrapiService {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
      allStrapiTechnology {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
    }
  `)

  const services =
    data?.allStrapiService?.edges?.map(edge => ({
      ...edge.node,
    })) || []
  const technologies =
    data?.allStrapiTechnology?.edges?.map(edge => ({
      ...edge.node,
    })) || []

  return (
    <footer className={styles.container}>
      <div className={styles.footerLgBg}>
        <div className={styles.containerLg}>
          <div className={styles.colFooterLg}>
            <div className={styles.colFooter}>
              <h3>ARAMAZE</h3>
              <div className={styles.margB20}>
                <p className={styles.p14}>
                  PO Box 7101 <br />
                  Prospect, CT 06712 <br />
                  United States
                </p>
              </div>
              <p className={styles.p14}>
                <span>Phone:</span>
                <span>
                  <a href="tel:+12035275119" onClick={()=>gtagWrapper('event', 'click-phone-number', { 'event_category': 'click', })}>+1 (203) 527-5119</a>
                </span>
              </p>
              <p className={styles.p14}>
                <span>Email:</span>
                <span>
                  <a href="mailto:contact@aramaze.com" onClick={()=>gtagWrapper('event', 'click-email', { 'event_category': 'click', })}>contact@aramaze.com</a>
                </span>
              </p>
            </div>
            <div className={`${styles.colFooter} + ${styles.hiddMob}`}>
              <p className={styles.p16}>Useful Links</p>
              <ul className={styles.footerUl}>
                <FooterLink text="Home" icon="chevron-right" to="/#home" />
                <FooterLink
                  text="About us"
                  icon="chevron-right"
                  to="/about-us/"
                />
                <FooterLink
                  text="Services"
                  icon="chevron-right"
                  to="/#services"
                />
                <FooterLink
                  text="Technologies"
                  icon="chevron-right"
                  to="/#technologies"
                />
                <FooterLink
                  text="Projects"
                  icon="chevron-right"
                  to="/#projects"
                />
                <FooterLink
                  text="Contact Us"
                  icon="chevron-right"
                  to="/contact-us/"
                />
              </ul>
            </div>
            <div className={`${styles.colFooter} + ${styles.hiddMob}`}>
              <p className={styles.p16}>Our Services</p>
              <ul className={styles.footerUl}>
                {services.map(link => (
                  <FooterLink
                    key={link.slug}
                    text={link.title}
                    icon="chevron-right"
                    to={`/services/${link.slug}/`}
                  />
                ))}
              </ul>
            </div>
            <div className={`${styles.colFooter} + ${styles.hiddMob}`}>
              <p className={styles.p16}>Our Technologies</p>
              <ul className={styles.footerUl}>
                {technologies.map(link => (
                  <FooterLink
                    key={link.slug}
                    text={link.title}
                    icon="chevron-right"
                    to={`/technologies/${link.slug}/`}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerSm}>
        <div className={styles.wrap}>
          <div className={styles.upper}>
            <div className={styles.left}>
              <ul>
                <li>
                  © 2015 - {new Date().getFullYear()} Aramaze | All rights
                  reserved
                </li>
              </ul>
            </div>
            <div className={styles.right}>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/aramaze/"
                    className={styles.socialLink}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    <Icon icon="linkedin2" size={15} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/aramaze.official"
                    className={styles.socialLink}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    <Icon icon="facebook" size={15} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/aramazesoft"
                    className={styles.socialLink}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    <Icon icon="instagram" size={15} />
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://www.linkedin.com/"
                    className={styles.socialLink}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    <Icon icon="linkedin2" size={15} />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
