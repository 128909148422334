import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./Body.module.scss"

const Body = ({ children, className }) => (
  <div className={cx(styles.container, className)}>{children}</div>
)

Body.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Body
