import React from "react"
import { Link } from "gatsby"

import Icon from "../../Icon"

const FooterLink = ({ text, icon, to }) => (
  <li>
    <i>
      <Icon icon={icon} />
    </i>
    <Link to={to}>{text}</Link>
  </li>
)

export default FooterLink
