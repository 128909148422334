import React, { useMemo } from "react"
import { Link as GatsbyLink } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import { useLocation } from "@reach/router"

const SmartLink = ({
  pathForScroll,
  className,
  to,
  activeClass,
  children,
  ...rest
}) => {
  const { pathname } = useLocation()

  const toCalc = useMemo(() => {
    if (pathname === pathForScroll) return to

    return `${pathForScroll}#${to}`
  }, [pathname, pathForScroll, to])

  return pathForScroll === pathname ? (
    <ScrollLink
      className={className}
      to={toCalc}
      activeClass={activeClass}
      {...rest}
    >
      {children}
    </ScrollLink>
  ) : (
    <GatsbyLink
      className={`${className} ${
        pathname.startsWith(`/${to}`) ? activeClass : ""
      }`}
      to={toCalc}
    >
      {children}
    </GatsbyLink>
  )
}

export default React.memo(SmartLink)
